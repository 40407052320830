<template>
  <div>   
     <b-row class="justify-content-center">
        <b-col cols="11" md="11">
            <br><br><br><br>
            <img src="elementos/logo.png" class="rounded float-right" alt="Image" :style="isMobile ? 'width:65%;' : ''">
            <p class="text-white mt-5 text-right" >
                <br><br v-if="!isMobile"><br  v-if="!isMobile">
                {{user.id_pdv}} - {{user.email}}  <u class="ml-5 mt-5 text-right" style="color:#5cb617; cursor: pointer;" @click="$router.push('/bienvenida')">Regresar</u>
            </p>
        </b-col>
        <!-- <b-col cols="12" md="3">
        </b-col> -->
        <b-col cols="11" md="8" class="text-center">
           <br>
            <b-row class="justify-content-center">
                <!-- <b-col cols="6" md="1">
                    <v-btn block  color="#5cb617"  class="mt-2"   x-large dark @click="$router.push('/bienvenida')" style="text-transform:capitalize; font-size:12px;" >
                        Regresar
                    </v-btn>
                </b-col> -->
                <b-col cols="6" md="2">
                    <v-btn block  :color="nav == 1 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(1)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        Objetivo
                    </v-btn>
                </b-col>
                <b-col cols="6" md="2">
                    <v-btn block :color="nav == 2 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(2)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        Duración
                    </v-btn>
                </b-col>
                <b-col cols="6" md="2">
                    <v-btn block :color="nav == 3 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(3)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        ¿Quién participa?
                    </v-btn>
                </b-col>
                <b-col cols="6" md="2">
                    <v-btn block :color="nav == 4 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(4)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        ¿Cómo me inscribo?
                    </v-btn>
                </b-col>
                <b-col cols="6" md="2">
                    <v-btn block :color="nav == 5 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(5)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        Estructura general
                    </v-btn>
                </b-col>
                <b-col cols="6" md="2">
                    <v-btn block :color="nav == 6 ? '#187abe' : '#5cb617'"  class="mt-2"    @click="cambiarNav(6)" style="text-transform:none; font-size:14px; letter-spacing:1px;" >
                        Criterios selección
                    </v-btn>
                </b-col>
           </b-row>
        </b-col>
      </b-row>
       <br><br><br>
     <b-row class="justify-content-center">
        <b-col cols="11" md="11">
            <template v-if="nav == 1">
                    <h3 class="text-white text-center">OBJETIVO</h3>
                    <br>
                    <p class="text-white text-center">Premiar el esfuerzo y dedicación de los integrantes más descatados de nuestra fuerza de venta a nivel nacional, 
                        evaluando el trabajo en equipo, <br> el conocimiento del negocio y especialmente el cumplimiento constante de las cuotas comerciales.
                    </p>
            </template>

            <template v-if="nav == 2">
                    <h3 class="text-white text-center">DURACIÓN</h3>
                    <br>
                    <p class="text-white text-center">
                        El periodo de participación  de llevará a cabo del 01 de mayo al 30  de septiembre 2022, los resultados finales <br> se publicarán los primeros días de octubre.
                    </p>
            </template>

            <template v-if="nav == 3">
                    <h3 class="text-white text-center">¿QUIÉN PARTICIPA?</h3>
                    <br>
                    <p class="text-white text-center">
                    Participan Ejecutivos, Gerentes y Líderes de todas las regiones, con una antiguedad en la empresa <br> mínima de 3 meses.
                    </p>
            </template>

            
            <template v-if="nav == 4">
                    <h3 class="text-white text-center">¿CÓMO ME INSCRIBO?</h3>
                    <br>
                    <p class="text-white text-center">
                        Al cumplir con los requisitos mencionados arriba, quedas inscrito automáticamente y se empieza a contabilizar tu cumplimiento de cuotas.
                        <br><br>

                        Más adelante te daremos acceso a una plataforma para que puedas participar en las misiones y acumular más puntos, así como poder ver tu avance en el juego. <br>
                        Mantente pendiente de los comunicados.

                    </p>
            </template>
            
            <template v-if="nav == 5">
                    <h3 class="text-white text-center">ESTRUCTURA GENERAL</h3>
                    <br>
                    <p class="text-white text-center">
                    El juego consta de 2 ejes: Logros y Desafíos.
                    <br><br>
                    Logros: relacionados con el cumplimiento de cuota.
                    <br><br>
                    Desafíos: 3 tipos de misiones: trivias, retos y pistas ocultas. Los agentes participantes podrán ir complementando las diferentes misiones con lo que irán <br>
                    sumando puntos, los que al final de juego sumen más puntos serán los ganadores.
                    </p><br>
                    <h3 style="color:#5cb617;" class="text-center">LOGROS + DESAFÍOS = Total de Puntos Acumulados por Participante.</h3>
                    <br>
                    <p class="text-white text-center">
                        Podrás revisar tu avance dentro de la plataforma  Asistente Movistar, en la sección del concurso, a través del tablero virtual del juego que encontrarás ahí.
                    </p>
            </template>

            <template v-if="nav == 6">
                    <h3 class="text-white text-center">CRITERIOS DE SELECCIÓN</h3>
                    <br>
                    <p class="text-white text-center">
                    Se seleccionarán a 45 ganadores entre los colaboradores del Canal Propio de acuerdo con la siguiente tabla: 
                    </p>
                    <br>
                    <center>
                        <img src="elementos/basepropio.PNG" class="rounded" alt="Image" :style="isMobile ? 'width:80%;' : ''">
                    </center>
                    <br>
                    <p class="text-white text-center">
                    Eje.1 - Logros:  El objetivo de este eje es superar las cuotas comerciales de manera mensual, ya que los puntos que se pueden obtener <br>
                        están 100% relacionados con el cumplimiento de las cuotas mensuales. 
                    </p>
                    <br>
                    <center>
                        <img src="elementos/basespropio2.PNG" class="rounded" alt="Image" :style="isMobile ? 'width:100%;' : 'width:40%;'">
                    </center>
                
            </template>
        </b-col>
     </b-row>
  </div>
</template>

<script>
export default {
  name: 'Base',
  data(){
    return{
      isMobile: this.$mobile(),
      loader:false,
      nav:1
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods:{
    cambiarNav(id){
        this.nav = id;
    },
  }
}
</script>